import { computed, ref } from "vue";
import { useCreateFunctionMutation } from "@/graphql/types";
import { useToast } from "primevue/usetoast";
import { trim } from "@/graphql/utils/utils";
import { useI18n } from "vue-i18n";

export const useCreateFunction = () => {
  const visible = ref(false);
  const refCreateFucntion = ref<any>(null);
  const toast = useToast();
  const { t } = useI18n();
  const { loading, mutate, onDone, onError } = useCreateFunctionMutation({
    update: (cache, { data }) => {
      if (data?.createFunction) {
        cache.modify({
          fields: {
            functions(value, { toReference }) {
              return [toReference(data.createFunction), ...value];
            },
          },
        });
      }
    },
  });
  const disableSubmit = computed(
    () => refCreateFucntion.value?.hasError?.value || loading.value
  );

  onDone(({ errors }) => {
    toggle();
    if (errors) {
      toast.add({
        severity: "warn",
        summary: t("create.title"),
        detail: t("create.failed"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    } else refCreateFucntion.value?.resetFields();
  });

  onError((err) => {
    console.log(err);
    toast.add({
      severity: "warn",
      summary: t("create.title"),
      detail: t("create.failed"),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
  });

  const toggle = () => (visible.value = !visible.value);

  function submit() {
    refCreateFucntion.value?.validateFields().then(async (input) => {
      trim(input);
      void mutate({ input });
    });
  }

  return {
    refCreateFucntion,
    disableSubmit,
    loading,
    visible,
    toggle,
    submit,
  };
};
