import { useRemoveFunctionMutation } from "@/graphql/types";
import { Reference } from "@apollo/client";
import { PrimeIcons } from "primevue/api";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";

export const useRemoveFunction = () => {
  const confirm = useConfirm();
  const toast = useToast();
  const { t } = useI18n();
  const {
    loading: removeLoading,
    mutate,
    onDone,
    onError,
  } = useRemoveFunctionMutation({
    update: (cache, { data }) => {
      const id = data?.removeFunction;
      if (id) {
        cache.modify({
          fields: {
            functions(value, { readField }) {
              return value.filter(
                (ref: Reference) => readField("id", ref) !== id
              );
            },
          },
        });
      }
    },
  });

  onDone(({ errors }) => {
    if (errors) {
      toast.add({
        summary: t("delete.title"),
        detail: t("delete.failed"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
        severity: "info",
      });
    } else {
      toast.add({
        summary: t("delete.title"),
        detail: t("delete.success"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
        severity: "success",
      });
    }
  });

  onError(() => {
    toast.add({
      summary: t("delete.title"),
      detail: t("delete.failed"),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      severity: "error",
    });
  });

  const handleRemove = (event: any, id: number) => {
    event.preventDefault();
    confirm.require({
      message: t("delete.confirm"),
      accept: () => void mutate({ id }),
      icon: PrimeIcons.INFO_CIRCLE,
      rejectClass: "p-button-secondary p-button-outlined p-button-sm",
      acceptClass: "p-button-sm p-button-danger",
      acceptLabel: t("prime.accept"),
      rejectLabel: t("prime.reject"),
      target: event.currentTarget,
    });
  };

  return {
    handleRemove,
    removeLoading,
  };
};
